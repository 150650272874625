// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import Swiper from "swiper";

// custom

jQuery(document).ready(function () {

    new Swiper(".swiper-container", {
        "loop": true,
        "autoplay": true,
        "delay": 5000,
        "speed": 800,
    });

    if (!("ontouchstart" in document.documentElement)) {
        document.documentElement.className += " no-touch";
    } else {
        document.documentElement.className += " touch";
    }
    jQuery(".touch .main-navigation ul li.menu-item-has-children > a").on("click touchstart", function (event) {
        if (!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0) {
            jQuery(this).parent().find("li").removeClass("open");
            event.preventDefault();
            event.stopPropagation();
            jQuery(this).parent().addClass("open").siblings().removeClass("open");
        }
    });

    jQuery(".menu-toggle").click(function () {
        jQuery("body").toggleClass("menu-open");
    });
    jQuery(".overlay").on("click touchstart",function () {
        jQuery("body").removeClass("menu-open");
        jQuery(".main-navigation ul li").removeClass("open");
    });
    jQuery(".close-icon").on("click touchstart",function () {
        jQuery("body").removeClass("menu-open");
        jQuery(".main-navigation ul li").removeClass("open");
    });

});
jQuery(window).resize(function () {

});
